module.exports = {
  defaultLocale: 'en',
  locales: ['en'],
  prerender: true,
  analytics: {
    gtag: '',
    gtmId: 'GTM-PKSH5SD',
    optimizeId: '',
    hotjarId: '',
  },
  seo: {
    title: 'Matchpoint',
  },
  stage: {
    mobile: 320,
    tablet: 768,
    desktop: 1440,
  },
  mediaQuery: {
    tablet: 576,
    desktop: 992,
    xl: 1920,
  },
};

import React from 'react';
import styled from 'styled-components';

import ArrowButton from 'components/button/ArrowButton';

import vw from 'src/styles/utils';

const NotFound = () => {
  return (
    <Root>
      <h1>Page not Found</h1>
      <ArrowButton text="Back to Home" to="/" />
    </Root>
  );
};

const Root = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.champagne};
  h1 {
    ${vw('margin-bottom', 20)}
  }
`;

export default NotFound;

import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import vw from './utils';
import media from './media';

export default createGlobalStyle`
  // for React-Reveal FadeUp component
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(10%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .fadeInUp {
    animation: fadeInUp 0.4s ease;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    font-family: ${theme.font.family};
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: ${theme.color.primary};
  }

  main {
    width: 100%;
    height: 100%;
  }

  .copper {
    color: ${theme.color.copperPrimary};
  }

  .brown {
    color: ${theme.color.brownPrimary};
  }

  .white {
    color: ${theme.color.offwhite};
  }

  .champagne {
    color: ${theme.color.champagne}
  }

  h1 {
    font-family: ${theme.font.family};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    ${vw('font-size', 30, 48)}
    ${vw('line-height', 38, 64)}
    &.hero-homepage {
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
      color: white;
      ${vw('font-size', 11, 24, 28)}
      ${vw('letter-spacing', 3, 7.29, 8.5)}
    }
    &.footer {
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      ${vw('font-size', 15)}
      ${vw('line-height', 19)}
      ${vw('letter-spacing', 0.5)}
    }
  }

  p {
    font-family: ${theme.font.family};
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    ${vw('font-size', 13, 15)}
    ${vw('line-height', 21, 28)}
    &.footer {
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      ${vw('font-size', 13)}
      ${vw('line-height', 23)}
      @media ${media.desktop} {
        font-weight: 500;
      }
    }
  }

  .nav {
    &.menu-icon {
      font-family: ${theme.font.family};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      text-align: center;
      ${vw('font-size', 10, 18)}
      ${vw('letter-spacing', 2, 4)}
    }
    &.text-link {
      font-family: ${theme.font.family};
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      ${vw('font-size', 13, 15)}
      ${vw('line-height', 26, 32)}
      ${vw('letter-spacing', 0.5)}
    }
  }

  .strapline {
    font-family: ${theme.font.family};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    ${vw('font-size', 11, 13)}
    ${vw('letter-spacing', 2)}
  }

  .footer {
    &.copyright {
      font-family: ${theme.font.family};
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      text-transform: uppercase;
      ${vw('font-size', 9, 9, 10)}
      ${vw('letter-spacing', 0.2, 0.2, 0.5)}
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { useSelector } from 'react-redux';

import ArrowButton from 'components/button/ArrowButton';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ContentSection = (props) => {
  const {
    title,
    title2,
    subtitle,
    image,
    smallImage,
    backgroundImage,
    mobileLetter,
    backgroundWidth,
    text,
    link,
    to,
    linkText,
    disabledButton,
    white,
    higherCircle,
    id,
    noTabletBreak,
    last,
    topBackground,
  } = props;

  const browser = useSelector((state) => state.browser);

  var is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
  // var is_explorer = navigator.userAgent.indexOf('MSIE') > -1;
  // var is_firefox = navigator.userAgent.indexOf('Firefox') > -1;
  var is_safari = navigator.userAgent.indexOf('Safari') > -1;
  var is_opera = navigator.userAgent.toLowerCase().indexOf('op') > -1;
  if (is_chrome && is_safari) {
    is_safari = false;
  }
  if (is_chrome && is_opera) {
    is_chrome = false;
  }

  const handleDuration = (last) => {
    if (is_safari) {
      return last
        ? window.innerHeight / 4 - window.innerHeight / 6
        : window.innerHeight / 4;
    } else {
      return last
        ? window.innerHeight / 2 - window.innerHeight / 6
        : window.innerHeight / 2;
    }
  };

  return (
    <Scene duration={window.innerHeight / 2} triggerHook="onCenter">
      {(progress) => (
        <Root white={white} id={id} last={last} topBackground={topBackground}>
          <MobileCircle
            white={white}
            higherCircle={higherCircle}
            text={text}
            last={last}
          />
          <Container>
            <Scene
              duration={window.innerHeight + window.innerHeight / 3}
              offset={-(window.innerHeight / 3)}
              triggerHook="onEnter"
              triggerElement={`#${id}`}
            >
              {(progress) => (
                <Timeline totalProgress={progress} paused>
                  <Tween from={{ y: '10%' }} to={{ y: '0' }}>
                    <Circle white={white} higherCircle={higherCircle} />
                  </Tween>
                </Timeline>
              )}
            </Scene>
          </Container>
          <Scene
            duration={handleDuration(last)}
            pin
            triggerHook="onLeave"
            enabled={browser.is.desktop}
            pinSettings={{ pushFollowers: browser.is.desktop ? true : false }}
          >
            <Wrapper>
              <Title noTabletBreak={noTabletBreak} active={progress > 0.75}>
                <div>
                  <h2 className="strapline brown">{subtitle}</h2>
                  <h1 className="copper">{title}</h1>
                </div>
                {title2 && (
                  <div>
                    <h1 className="copper">{title2}</h1>
                  </div>
                )}
              </Title>
              <Timeline totalProgress={progress} paused>
                <Tween
                  from={{
                    transform: 'translate(-50%, -50%) scale(0.8)',
                    opacity: 0,
                  }}
                  to={{
                    transform: 'translate(-50%, -50%) scale(1)',
                    opacity: 1,
                  }}
                >
                  <Letter
                    src={backgroundImage}
                    alt="background"
                    width={backgroundWidth}
                  />
                </Tween>
              </Timeline>
              <Image image={image}>
                <img src={mobileLetter} alt="" />
              </Image>
              <Content link={linkText} active={progress > 0.75}>
                {text && (
                  <div>
                    <p className="brown text">{text}</p>
                    {linkText && (
                      <ArrowButton
                        href={link}
                        to={to}
                        text={linkText}
                        disabled={disabledButton}
                      />
                    )}
                  </div>
                )}
                {smallImage && <SmallImage src={smallImage} alt="" />}
              </Content>
            </Wrapper>
          </Scene>
        </Root>
      )}
    </Scene>
  );
};

ContentSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title2: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.string,
  image: PropTypes.string,
  smallImage: PropTypes.string,
  backgroundImage: PropTypes.string,
  mobileLetter: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  id: PropTypes.string,
  disabledButton: PropTypes.bool,
  white: PropTypes.bool,
  higherCircle: PropTypes.bool,
  noTabletBreak: PropTypes.bool,
  last: PropTypes.bool,
  topBackground: PropTypes.bool,
  backgroundWidth: PropTypes.object,
};

const Root = styled.section`
  background-color: ${(props) =>
    props.white ? props.theme.color.offwhite : props.theme.color.champagne};
  margin-top: ${vwMobile(60)};
  padding: ${vwMobile(90)} ${vwMobile(30)};
  position: relative;
  @media ${media.tablet} {
    margin-top: ${vwTablet(150)};
    padding: ${vwTablet(120)} ${vwTablet(90)};
  }
  @media ${media.desktop} {
    height: 100vh;
    margin-top: 66.67vh;
    padding: 0 ${vwDesktop(123)};
    &::before {
      content: '';
      display: ${(props) => (props.topBackground ? 'block' : 'none')};
      background-color: ${(props) =>
        props.white ? props.theme.color.champagne : props.theme.color.offwhite};
      position: absolute;
      left: 0;
      bottom: 80%;
      width: 100%;
      height: 33.33vh;
      z-index: 0;
    }
    &::after {
      content: '';
      display: ${(props) => (props.last ? 'none' : 'block')};
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      height: 66.67vh;
      background-color: ${(props) =>
        props.white ? props.theme.color.offwhite : props.theme.color.champagne};
      z-index: 0;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  @media ${media.tablet} {
    display: flex;
    flex-direction: column;
  }
  @media ${media.desktop} {
    margin-top: -33.33vh;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

const Title = styled.div`
  h2 {
    margin-bottom: ${vwMobile(10)};
  }
  @media ${media.tablet} {
    h1 br {
      display: ${(props) => (props.noTabletBreak ? 'none' : 'block')};
    }
    h2 {
      margin-bottom: ${vwTablet(20)};
    }
  }
  @media ${media.desktop} {
    width: ${vwDesktop(338)};
    height: 100%;
    transition: 0.4s ease;
    opacity: ${(props) => (props.active ? 1 : 0)};
    transform: ${(props) =>
      props.active ? 'translateY(0)' : 'translateY(5%)'};
    h1 br {
      display: block;
    }
    h2 {
      margin-bottom: ${vwDesktop(20)};
    }
    > div {
      height: 50%;
      &:first-of-type {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
  }
`;

const Content = styled.div`
  .text {
    margin-bottom: ${(props) => (props.link ? vwMobile(20) : 0)};
  }
  @media ${media.tablet} {
    width: ${vwTablet(297)};
    align-self: flex-end;
    .text {
      margin-bottom: ${(props) => (props.link ? vwTablet(30) : 0)};
    }
  }
  @media ${media.desktop} {
    width: ${vwDesktop(297)};
    height: 100%;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: 0.4s ease;
    opacity: ${(props) => (props.active ? 1 : 0)};
    transform: ${(props) =>
      props.active ? 'translateY(0)' : 'translateY(5%)'};
    position: relative;
    > div {
      height: 50%;
    }
    .text {
      margin-bottom: ${(props) => (props.link ? vwDesktop(30) : 0)};
    }
  }
`;

const Circle = styled.div`
  background-color: ${(props) =>
    props.white ? props.theme.color.offwhite : props.theme.color.champagne};
  @media ${media.desktop} {
    width: 160vw;
    height: 160vw;
    position: absolute;
    top: ${(props) => (props.higherCircle ? '70%' : '85%')};
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
`;

const MobileCircle = styled.div`
  background-color: ${(props) =>
    props.white ? props.theme.color.offwhite : props.theme.color.champagne};
  width: 300vw;
  height: 300vw;
  position: absolute;
  top: ${(props) => {
    if (props.last) {
      return '56%';
    } else if (props.higherCircle) {
      return '60%';
    } else {
      return '50%';
    }
  }};
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  @media ${media.tablet} {
    width: 200vw;
    height: 200vw;
    top: ${(props) => (props.higherCircle ? '55%' : '51.4%')};
  }
  @media ${media.desktop} {
    display: none;
  }
`;

const Image = styled.div`
  width: 100%;
  height: ${vwMobile(340)};
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${vwMobile(20)} 0;
  img {
    display: block;
    width: ${vwMobile(220)};
    height: auto;

    opacity: 0.6;
  }
  @media ${media.tablet} {
    width: ${vwTablet(393)};
    height: ${vwTablet(512)};
    margin: ${vwTablet(40)} auto;
    img {
      display: none;
    }
  }
  @media ${media.desktop} {
    display: none;
  }
`;

const SmallImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: ${vwMobile(50)};
  height: auto;
  display: block;
  @media ${media.tablet} {
    width: ${vwTablet(70)};
    right: auto;
    left: ${vwTablet(98)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(102)};
    bottom: ${vwDesktop(80)};
    right: 0;
    left: auto;
  }
`;

const Letter = styled.img`
  width: ${(props) => vwDesktop(props.width.desktop)};
  height: auto;
  max-height: 95vh;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  display: none;
  z-index: -1;
  @media ${media.tablet} {
    display: block;
    width: ${vwTablet(640)};
  }
  @media ${media.desktop} {
    width: ${(props) => vwDesktop(props.width.desktop)};
  }
`;

export default ContentSection;

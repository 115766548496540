import React, { useState } from 'react';
import styled from 'styled-components';
import { Scene } from 'react-scrollmagic';
import { useSelector, useDispatch } from 'react-redux';
import { handleMenu } from 'src/redux/menu';

import Shapes from '../elements/Shapes';
import Menu from 'components/button/Menu';
import ArrowButton from 'components/button/ArrowButton';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = () => {
  const [hasScrolled, setHasScrolled] = useState(false);

  const browser = useSelector((state) => state.browser);
  const dispatch = useDispatch();

  const handleHasScrolled = () => setHasScrolled(true);

  return (
    <>
      <Scene
        duration={window.innerHeight}
        pin
        triggerHook="onLeave"
        enabled={browser.is.desktop}
        pinSettings={{
          pushFollowers: browser.is.desktop ? true : false,
        }}
      >
        {(progress) => (
          <Root>
            <Wrapper>
              <LinkWrapper>
                <ArrowButton
                  text={
                    <>
                      Chloé Kerrisdale{' '}
                      <span className="tablet">&nbsp;-&nbsp;</span>
                      <br className="mobile" /> Now Selling
                    </>
                  }
                  href="https://chloekerrisdale.com/"
                  white
                />
              </LinkWrapper>
              {/* <StyledMenu
                light
                handleClick={() => dispatch(handleMenu(true))}
              /> */}
              <Image
                src={require('src/assets/images/home/photo_collage_01.jpg')}
                alt="Home"
              />
              <Matchpoint
                src={require('src/assets/images/logos/matchpoint_logo_lines_mobile.svg')}
                alt="Matchpoint"
                mobile
              />
              <Matchpoint
                src={require('src/assets/images/logos/matchpoint_logo_lines.svg')}
                alt="Matchpoint"
              />
              <h1 className="hero-homepage">THE ART OF COMMUNITY</h1>
              <Shapes
                progress={progress}
                handleHasScrolled={handleHasScrolled}
                hasScrolled={hasScrolled}
              />
              <MobileShapes
                src={require('src/assets/images/home/homepage_shapes.svg')}
                alt="Shapes"
              />
            </Wrapper>
          </Root>
        )}
      </Scene>
      <Title id="title">
        <Matchpoint
          src={require('src/assets/images/logos/matchpoint_logo_lines.svg')}
          alt="Matchpoint"
        />
        <h1 className="hero-homepage">THE ART OF COMMUNITY</h1>
      </Title>
    </>
  );
};

const Root = styled.div`
  height: 85vh;
  min-height: ${vwMobile(460)};
  width: 100%;
  padding: ${vwMobile(10)};
  background-color: ${({ theme }) => theme.color.champagne};
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: white;
  }
  @media ${media.tablet} {
    height: 100vh;
    min-height: auto;
    padding: ${vwTablet(20)};
  }
  @media ${media.desktop} {
    height: 100vh;
    padding: ${vwDesktop(30)};
    margin-top: -100vh;
    &::before {
      height: 51.67vh;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${require('src/assets/images/texture/texture_paint.jpg')});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  h1 {
    position: relative;
    z-index: 3;
  }
`;

const LinkWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${`calc(13vh - ${vwMobile(10)})`};
  display: flex;
  align-items: center;
  justify-content: center;
  .tablet {
    display: none;
  }
  .text-link {
    line-height: ${vwMobile(18)};
  }
  > a {
    width: 100%;
    justify-content: space-between;
    padding: 0 ${vwMobile(20)};
  }
  @media ${media.tablet} {
    height: ${`calc(25vh - ${vwTablet(20)})`};
    .mobile {
      display: none;
    }
    .tablet {
      display: block;
    }
    .text-link {
      line-height: normal;
      display: flex;
    }
    > a {
      width: auto;
      justify-content: flex-start;
      padding: 0;
    }
  }
  @media ${media.desktop} {
    height: ${`calc(16.66667vh - ${vwDesktop(30)})`};
  }
`;

const Title = styled.div`
  display: none;
  pointer-events: none;
  @media ${media.desktop} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
  }
`;

const MobileShapes = styled.img`
  display: block;
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  bottom: 0;
  @media ${media.desktop} {
    display: none;
  }
`;

const Matchpoint = styled.img`
  display: ${(props) => (props.mobile ? 'block' : 'none')};
  width: ${vwMobile(272)};
  margin-bottom: ${vwMobile(8)};
  z-index: 3;
  @media ${media.tablet} {
    display: ${(props) => (props.mobile ? 'none' : 'block')};
    width: ${vwTablet(640)};
    margin-bottom: ${vwTablet(24)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(1060)};
    margin-bottom: ${vwDesktop(32)};
  }
`;

const Image = styled.img`
  width: ${vwMobile(260)};
  height: auto;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
  z-index: 3;
  @media ${media.tablet} {
    width: auto;
    height: 50vh;
  }
  @media ${media.desktop} {
    display: none;
  }
`;

const StyledMenu = styled(Menu)`
  position: absolute;
  top: ${vwDesktop(35)};
  left: 50%;
  transform: translateX(-50%);
`;

export default Hero;

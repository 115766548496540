import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Controller } from 'react-scrollmagic';

import theme from 'src/styles/theme';
import GlobalStyle from 'src/styles/global';
import 'src/styles/fonts.css';
import store from 'src/redux/store';
import UpdateLanguage from 'src/plugins/language';
import ScrollTop from 'src/plugins/scrollTop';
import SEO from 'src/plugins/SEO';
import Analytics from 'src/plugins/Analytics/Analytics';

import App from './App';

ReactDOM.render(
  <>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router basename={process.env.PUBLIC_URL}>
          <Controller>
            <>
              <SEO />
              <Analytics />
              <UpdateLanguage />
              <ScrollTop />
              <GlobalStyle />
              <App />
            </>
          </Controller>
        </Router>
      </Provider>
    </ThemeProvider>
  </>,
  document.getElementById('app')
);

import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Scene } from 'react-scrollmagic';
import { useSelector } from 'react-redux';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Intro = () => {
  const browser = useSelector((state) => state.browser);

  return (
    <Scene
      pin
      triggerHook="onLeave"
      duration={window.innerHeight / 2}
      enabled={browser.is.desktop}
      pinSettings={{ pushFollowers: browser.is.desktop ? true : false }}
    >
      <Root id="intro">
        <Wrapper>
          <h4 className="strapline brown">THE ART OF COMMUNITY</h4>
          <img
            src={require('src/assets/images/logos/matchpoint_logo_solid.svg')}
            alt="Matchpoint"
          />
          <p className="brown">
          Matchpoint Development is a Vancouver-based developer whose close, long-standing ties to the local community position us to identify ideal neighbourhood locations. Matchpoint’s dedication to flawless design and quality construction ensures we deliver the ultimate in urban livability coupled with old-world charm.
          </p>
        </Wrapper>
        <Landscape
          src={require('src/assets/images/home/van_illustrations_landscape.png')}
          alt="City"
        />
        <Mountains
          src={require('src/assets/images/home/van_illustrations_mountains.png')}
          alt="Mountains"
        />
        <Plane
          src={require('src/assets/images/home/van_illustrations_airplane.png')}
          alt="Airplane"
        />
        <Boat
          src={require('src/assets/images/home/van_illustrations_seabus.png')}
          alt="Seabus"
        />
      </Root>
    </Scene>
  );
};

const Root = styled.section`
  height: ${vwMobile(629)};
  padding-top: ${vwMobile(84)};
  background-image: url(${require('src/assets/images/texture/texture_paper.jpg')});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  position: relative;

  overflow: hidden;
  h4 {
    text-transform: uppercase;
  }
  @media ${media.tablet} {
    padding-top: 0;
    height: 100vh;
    align-items: center;
  }
  // targeting landscape phones
  @media (max-height: 420px) and (max-width: 825px) and (min-width: 600px) {
    height: ${vwTablet(629)};
    padding-top: ${vwTablet(84)};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* z-index: 3; */
  position: relative;
  padding: ${vwMobile(50)} ${vwMobile(25)};
  width: ${vwMobile(260)};
  height: fit-content;
  border: solid ${vwMobile(5)} ${({ theme }) => theme.color.orangePrimary};
  img {
    width: 100%;
    height: auto;
    margin: ${vwMobile(10)} 0 ${vwMobile(20)};
  }
  p {
    text-align: center;
    margin: 0 auto;
  }
  @media ${media.tablet} {
    border: solid ${vwTablet(10)} ${({ theme }) => theme.color.orangePrimary};
    width: ${vwTablet(393)};
    padding: ${vwTablet(80)} ${vwTablet(50)};
    img {
      padding: 0 ${vwTablet(4)};
      width: 100%;
      display: block;
      margin: ${vwTablet(20)} 0 ${vwTablet(30)};
    }
  }
  @media ${media.desktop} {
    display: none;
    border: solid ${vwDesktop(15)} ${({ theme }) => theme.color.orangePrimary};
    padding: 0;
    width: ${vwDesktop(460)};
    height: 66.67vh;
    img {
      padding: 0;
      width: ${vwDesktop(360)};
      display: block;
      margin: ${vwDesktop(24)} 0 ${vwDesktop(50)};
    }
    p {
      width: ${vwDesktop(360)};
    }
  }
`;

const StaticImage = styled.img`
  position: absolute;
  display: block;
  height: auto;
  mix-blend-mode: multiply;
  opacity: 0.7;
`;

const Landscape = styled(StaticImage)`
  width: ${vwMobile(300)};
  bottom: 0;
  right: ${vwMobile(10)};
  @media ${media.tablet} {
    width: ${vwTablet(461)};
    right: ${vwTablet(20)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(871)};
    right: ${vwDesktop(37)};
  }
`;

const Mountains = styled(StaticImage)`
  display: none;
  @media ${media.tablet} {
    display: block;
    bottom: ${vwTablet(66)};
    width: ${vwTablet(335)};
    left: ${vwTablet(20)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(634)};
    left: ${vwDesktop(30)};
    bottom: ${vwDesktop(108)};
  }
`;

const planeAnim = keyframes`
  0% {
  
    transform: translate(0, 30%) scale(1) rotate(0.03turn);
  }
  10% {
  
  }
  90% {
  
  }
  100% {

    transform: translate(-900%, -150%) scale(.7) rotate(0);
  }
`;

const planeCss = css`
  animation: ${planeAnim} 30s linear infinite;
`;

const Plane = styled(StaticImage)`
  display: none;
  opacity: 0.7;
  @media ${media.tablet} {
    display: block;
    width: ${vwTablet(116)};
    top: ${vwTablet(364)};
    right: ${vwTablet(40)};
    ${planeCss}
  }
  @media ${media.desktop} {
    width: ${vwDesktop(166)};
    top: ${vwDesktop(270)};
    right: ${vwDesktop(-130)};
  }
`;

const boatAnim = keyframes`
  0% {
   
    transform: translateX(0) ;
  }
 
  100% {
    transform: translateX(900%);
  }
`;

const boatCss = css`
  animation: ${boatAnim} 110s linear infinite;
`;

const Boat = styled(StaticImage)`
  display: none;
  opacity: 0.7;
  mix-blend-mode: normal;
  @media ${media.tablet} {
    display: block;
    width: ${vwTablet(105)};
    bottom: ${vwTablet(4)};
    left: ${vwTablet(102)};
    ${boatCss}
  }
  @media ${media.desktop} {
    width: ${vwDesktop(198)};
    bottom: ${vwDesktop(0)};
    left: ${vwDesktop(-200)};
  }
`;

export default Intro;

export default {
  color: {
    orangePrimary: '#d67c20',
    brownPrimary: '#783325',
    copperPrimary: '#c25822',
    brownDark: '#471012',
    brownLight: '#974318',
    offwhite: '#fdfbf9',
    champagne: '#ecd7c9',
    brownBodycopy: '#4e1d13',
  },
  font: {
    family: 'Beatrice',
  },
};

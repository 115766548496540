// Constants
export const MENU__HANDLEMENU = 'MENU::HANDLEMENU';

// Actions
export const handleMenu = (menuState) => ({
  type: MENU__HANDLEMENU,
  menuState,
});

// Initial State
const initialState = {
  isOpen: false,
};

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case MENU__HANDLEMENU:
      return {
        ...state,
        isOpen: action.menuState,
      };
    default:
      return state;
  }
};

import React from 'react';
import styled from 'styled-components';

import theme from 'src/styles/theme';
import vw from 'src/styles/utils';

const Menu = (props) => {
  const { light, handleClick } = props;

  return (
    <SVG
      width="66px"
      height="45px"
      viewBox="0 0 66 45"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
      {...props}
    >
      <g
        id="_assets"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Assets" transform="translate(-737.000000, -167.000000)">
          <g id="Group" transform="translate(736.636362, 162.000000)">
            <text
              id="MENU"
              fill={light ? theme.color.offwhite : theme.color.brownPrimary}
              className="nav menu-icon"
            >
              <tspan x="0.023" y="19">
                MENU
              </tspan>
            </text>
            <path
              d="M7.44275693,34 L62.1275179,34 C64.6241607,34.975346 64.6241607,38.0583451 62.1275179,38.9733315 L7.44275693,38.9733315 C5.51908102,40.0367641 5.51908102,43.0718333 7.44275693,44 L62.1275179,44 C64.5635051,44.9872804 64.5635051,48.0612254 62.1275179,49 L7.44275693,49"
              id="desktop_menu_icon_white"
              stroke={light ? theme.color.offwhite : theme.color.brownPrimary}
            ></path>
          </g>
        </g>
      </g>
    </SVG>
  );
};

const SVG = styled.svg`
  display: block;
  height: auto;
  cursor: pointer;
  ${vw('width', 41, 71)}
`;

export default Menu;

import React, { useRef, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Scene } from 'react-scrollmagic';

import Hero from './sections/Hero';
import Intro from './sections/Intro';
import ContentSection from './sections/ContentSection';
import ScrollingImage from './elements/ScrollingImage';

import media from 'src/styles/media';

const Home = () => {
  const [scrollingSection, setScrollingSection] = useState(undefined);
  const $section = useRef();

  const handleResize = useCallback(() => {
    clearTimeout(window.timeoutFinished);
    window.timeoutFinished = setTimeout(() => {
      if ($section.current) {
        setScrollingSection($section.current.offsetHeight);
      }
    }, 250);
  }, []);

  useEffect(() => {
    // Sets the section height to state so the Scene will re-render to the proper height
    if ($section.current) {
      setScrollingSection($section.current.offsetHeight);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <Root ref={$section} id="home">
      <Scene
        duration={scrollingSection ? scrollingSection : '100%'}
        triggerElement="#home"
      >
        {(progress) => <ScrollingImage progress={progress} />}
      </Scene>
      <Hero />
      <Intro />
      <Wrapper>
        <ContentSection
          title={
            <>
              The Art of
              <br /> Community
            </>
          }
          subtitle="PHILOSOPHY"
          text="Our aim, at Matchpoint, is to not only advantageously use Vancouver’s natural, scenic beauty but also to enrich it through inspired design. Matchpoint’s team of experienced consultants is well-positioned to embrace the city’s status as an up and coming dynamic international centre offering unique opportunities for innovative housing. "
          linkText="Coming Soon"
          to="/#"
          disabledButton
          image={require('src/assets/images/home/photo_collage_community.jpg')}
          backgroundImage={require('src/assets/images/home/o_lines.svg')}
          mobileLetter={require('src/assets/images/home/o_lines_mobile.svg')}
          backgroundWidth={{
            desktop: 850,
          }}
          higherCircle
          id="philosophy"
        />
        <ContentSection
          title={
            <>
              A Home <br />
              to Love.
            </>
          }
          // title2={
          //   <>
          //     A Story <br />
          //     Unmatched.
          //   </>
          // }
          subtitle="HOMES"
          text="Creating inviting and lasting homes is Matchpoint’s tireless purpose. Our aim is to design luxurious and enchanting residences that provide for a lifetime of special moments."
          linkText="Coming Soon"
          to="/homes"
          disabledButton
          white
          image={require('src/assets/images/home/photo_collage_homes.jpg')}
          backgroundImage={require('src/assets/images/home/m_lines.svg')}
          mobileLetter={require('src/assets/images/home/m_lines_mobile.svg')}
          backgroundWidth={{
            desktop: 886,
          }}
          id="homes"
          noTabletBreak
          topBackground
        />
        <ContentSection
          title={
            <>
              Chloe,
              <br />
              Kerrisdale
            </>
          }
          subtitle="NOW SELLING"
          text="Chloe embodies everything the Matchpoint team has envisioned for exacting West Coast homebuyers: a home of prestige and value, that families will cherish for a lifetime."
          linkText="Visit Website"
          link="https://chloekerrisdale.com/"
          image={require('src/assets/images/home/photo_collage_chloe.jpg')}
          backgroundImage={require('src/assets/images/home/c_lines.svg')}
          mobileLetter={require('src/assets/images/home/c_lines_mobile.svg')}
          backgroundWidth={{
            desktop: 739,
          }}
          id="nowselling"
          last
          smallImage={require('src/assets/images/home/chloe_bird.png')}
          topBackground
        />
      </Wrapper>
    </Root>
  );
};

const Root = styled.main``;

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  background-color: #fcf7f4;
  @media ${media.desktop} {
    margin-bottom: 33.33vh;
  }
`;

export default Home;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Timeline, Tween } from 'react-gsap';

import media from 'src/styles/media';

const Shapes = (props) => {
  const { progress, handleHasScrolled, hasScrolled } = props;

  // Having a hard time stacking the title so this targets a pseudo-title outside the Hero stacking context and hides it when the user has scrolled past (and the original title stacking context works again)
  useEffect(() => {
    if (document.getElementById('title')) {
      if (progress < 1) {
        document.getElementById('title').style.opacity = 1;
      } else {
        document.getElementById('title').style.opacity = 0;
      }
    }
    // Only let the scroll animation on the square happen on the first scroll
    if (progress >= 1) {
      handleHasScrolled();
    }
  }, [progress, handleHasScrolled]);

  return (
    <Timeline totalProgress={hasScrolled ? 1 : progress} paused>
      <Root
        width="1380px"
        height="480px"
        viewBox="0 0 1380 480"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="homepage_shapes_biggercanvas"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Group" transform="translate(0.000000, 165.000000)">
            <polygon
              id="Rectangle"
              fill="#C25822"
              transform="translate(342.500000, 157.500000) rotate(-270.000000) translate(-342.500000, -157.500000) "
              points="185 0 500 0 500 315 185 315"
            ></polygon>
            <path
              d="M7,284.780698 C48.9130824,325.255691 115.701814,324.089889 156.176807,282.176807 C196.6518,240.263725 195.485999,173.474993 153.572916,133 L7,284.780698 Z"
              id="Oval"
              fill="#ECD7C9"
            ></path>
            <circle
              id="Oval"
              fill="#7C3A2E"
              cx="605.5"
              cy="209.5"
              r="105.5"
            ></circle>

            {/* Animating rectangle */}
            <Tween from={{ rotate: -188 }} to={{ rotate: -236 }}>
              <polygon
                id="Rectangle"
                className="rotating-rectangle"
                fill="#ECD7C9"
                transform="translate(862.500000, 162.500000) rotate(-236.000000) translate(-862.500000, -162.500000)"
                points="753 53 841.245862 53 972 53 972 272 753 272"
                style={{ transformOrigin: '7.6% -10%' }}
              ></polygon>
            </Tween>

            <polygon
              id="Rectangle"
              fill="#7C3A2E"
              transform="translate(1018.500000, 261.500000) rotate(-270.000000) translate(-1018.500000, -261.500000) "
              points="965 208 1072 208 1072 315 965 315"
            ></polygon>
            <path
              d="M1100.75826,53 C1043.5099,118.856705 1050.48835,218.65308 1116.34505,275.90144 C1182.20176,333.149801 1281.99813,326.171359 1339.24649,260.314653 L1100.75826,53 Z"
              id="Oval"
              fill="#C25822"
            ></path>
            <circle
              id="Oval"
              fill="#7C3A2E"
              cx="52.5"
              cy="162.5"
              r="52.5"
            ></circle>
            <path
              d="M1352.88654,277 C1391.89476,233.723051 1388.39693,167.051368 1345.07391,128.084627 C1301.75088,89.1178874 1235.00822,92.6120022 1196,135.888951 L1352.88654,277 Z"
              id="Oval"
              fill="#7C3A2E"
            ></path>
          </g>
        </g>
      </Root>
    </Timeline>
  );
};

Shapes.propTypes = {
  progress: PropTypes.number,
  handleHasScrolled: PropTypes.func,
  hasScrolled: PropTypes.bool,
};

const Root = styled.svg`
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;
  @media ${media.desktop} {
    display: block;
  }
`;

export default Shapes;

import React, { forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import useImage from 'components/hooks/useImage';

import { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

// code reference: https://codesandbox.io/s/vbswn

const progressTimings = {
  intro: [0.295, 0.353],
  section1: [0.53, 0.58],
  section2: [0.72, 0.77],
  section3: [0.92, 1],
};

const ScrollingImage = forwardRef((props, ref) => {
  const { progress } = props;

  const data = [
    useImage(require('src/assets/images/home/photo_collage_01.jpg')),
    useImage(require('src/assets/images/home/photo_collage_01.jpg')),
    useImage(require('src/assets/images/home/photo_collage_01.jpg')),
    useImage(require('src/assets/images/home/photo_collage_01.jpg')),
    useImage(require('src/assets/images/home/photo_collage_02.jpg')),
    useImage(require('src/assets/images/home/photo_collage_03.jpg')),
    useImage(require('src/assets/images/home/photo_collage_04.jpg')),
    useImage(require('src/assets/images/home/photo_collage_05.jpg')),
    useImage(require('src/assets/images/home/photo_collage_06.jpg')),
    useImage(require('src/assets/images/home/photo_collage_07.jpg')),
    useImage(require('src/assets/images/home/photo_collage_08.jpg')),
    useImage(require('src/assets/images/home/photo_collage_09.jpg')),
    useImage(require('src/assets/images/home/photo_collage_10.jpg')),
    useImage(require('src/assets/images/home/photo_collage_11.jpg')),
    useImage(require('src/assets/images/home/photo_collage_12.jpg')),
    useImage(require('src/assets/images/home/photo_collage_01.jpg')),
    useImage(require('src/assets/images/home/photo_collage_02.jpg')),
    useImage(require('src/assets/images/home/photo_collage_03.jpg')),
    useImage(require('src/assets/images/home/photo_collage_04.jpg')),
    useImage(require('src/assets/images/home/photo_collage_05.jpg')),
    useImage(require('src/assets/images/home/photo_collage_06.jpg')),
    useImage(require('src/assets/images/home/photo_collage_07.jpg')),
    useImage(require('src/assets/images/home/photo_collage_08.jpg')),
    useImage(require('src/assets/images/home/photo_collage_09.jpg')),
    useImage(require('src/assets/images/home/photo_collage_10.jpg')),
    useImage(require('src/assets/images/home/photo_collage_11.jpg')),
    useImage(require('src/assets/images/home/photo_collage_12.jpg')),
    useImage(require('src/assets/images/home/photo_collage_01.jpg')),
    useImage(require('src/assets/images/home/photo_collage_02.jpg')),
    useImage(require('src/assets/images/home/photo_collage_03.jpg')),
    useImage(require('src/assets/images/home/photo_collage_04.jpg')),
    useImage(require('src/assets/images/home/photo_collage_05.jpg')),
    useImage(require('src/assets/images/home/photo_collage_06.jpg')),
    useImage(require('src/assets/images/home/photo_collage_07.jpg')),
    useImage(require('src/assets/images/home/photo_collage_08.jpg')),
    useImage(require('src/assets/images/home/photo_collage_09.jpg')),
    useImage(require('src/assets/images/home/photo_collage_10.jpg')),
    useImage(require('src/assets/images/home/photo_collage_11.jpg')),
    useImage(require('src/assets/images/home/photo_collage_12.jpg')),
    useImage(require('src/assets/images/home/photo_collage_01.jpg')),
    useImage(require('src/assets/images/home/photo_collage_02.jpg')),
    useImage(require('src/assets/images/home/photo_collage_03.jpg')),
    useImage(require('src/assets/images/home/photo_collage_04.jpg')),
    useImage(require('src/assets/images/home/photo_collage_05.jpg')),
    useImage(require('src/assets/images/home/photo_collage_06.jpg')),
    useImage(require('src/assets/images/home/photo_collage_07.jpg')),
    useImage(require('src/assets/images/home/photo_collage_08.jpg')),
    useImage(require('src/assets/images/home/photo_collage_09.jpg')),
    useImage(require('src/assets/images/home/photo_collage_10.jpg')),
    useImage(require('src/assets/images/home/photo_collage_11.jpg')),
    useImage(require('src/assets/images/home/photo_collage_12.jpg')),
  ];

  let index = Math.round(progress * 1 * (data.length - 1));

  const renderImages = () => {
    if (data[index][1] !== undefined) {
      if (data[index][1] === 'loading') {
        return <Skeleton width="100%" height="100%" />;
      } else {
        return data.map((item, i) => (
          <span
            ref={ref}
            key={i}
            style={{
              display: i !== index ? 'none' : 'block',
              // opacity: i !== index ? 0 : 1,
              height: '100%',
              width: '100%',
              backgroundImage: `url('${item[0] ? item[0].src : null}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              transition: '0.4s ease',
            }}
          />
        ));
      }
    }
  };

  return (
    <Root ref={ref}>
      <Wrapper>
        <img
          src={require('src/assets/images/home/photo_collage_01.jpg')}
          alt=""
          style={{ opacity: 0 }}
          className="main"
        />
        <div
          style={{
            opacity:
              progress >= progressTimings.intro[0] &&
              progress <= progressTimings.intro[1]
                ? 0
                : 1,
            transition: '0.4s ease',
          }}
        >
          {renderImages()}
        </div>
        <Intro
          style={{
            opacity:
              progress >= progressTimings.intro[0] &&
              progress <= progressTimings.intro[1]
                ? 1
                : 0,
          }}
        >
          <h4 className="strapline brown">THE ART OF COMMUNITY</h4>
          <img
            src={require('src/assets/images/logos/matchpoint_logo_solid.svg')}
            alt="Matchpoint"
          />
          <p className="brown">
          Matchpoint Development is a Vancouver-based developer whose close, long-standing ties to the local community position us to identify ideal neighbourhood locations. Matchpoint’s dedication to flawless design and quality construction ensures we deliver the ultimate in urban livability coupled with old-world charm.
          </p>
        </Intro>
        <Image
          src={require('src/assets/images/home/photo_collage_community.jpg')}
          alt=""
          style={{
            opacity:
              progress >= progressTimings.section1[0] &&
              progress <= progressTimings.section1[1]
                ? 1
                : 0,
          }}
        />
        <Image
          src={require('src/assets/images/home/photo_collage_homes.jpg')}
          alt=""
          style={{
            opacity:
              progress >= progressTimings.section2[0] &&
              progress <= progressTimings.section2[1]
                ? 1
                : 0,
          }}
        />
        <Image
          src={require('src/assets/images/home/photo_collage_chloe.jpg')}
          alt=""
          style={{
            opacity:
              progress >= progressTimings.section3[0] &&
              progress <= progressTimings.section3[1]
                ? 1
                : 0,
          }}
        />
      </Wrapper>
    </Root>
  );
});

ScrollingImage.propTypes = {
  progress: PropTypes.number,
};

const Root = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 3;
  pointer-events: none;
  display: none;
  .main {
    display: block;
    height: 66.67vh;
    width: auto;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.15);
  }
  @media ${media.desktop} {
    display: flex;
  }
`;

const Wrapper = styled.div`
  position: relative;
  transition: 0.4s ease;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.15);
`;

const Intro = styled.div`
  @media ${media.desktop} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid ${vwDesktop(15)} ${({ theme }) => theme.color.orangePrimary};
    padding: 0;
    width: 100%;
    height: 66.67vh;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.4s ease;
    padding: 0 ${vwDesktop(40)};
    img {
      padding: 0;
      width: 100%;
      height: auto;
      display: block;
      margin: ${vwDesktop(24)} 0 ${vwDesktop(30)};
    }
    p {
      text-align: center;
      margin: 0 auto;
      width: 100%;
    }
  }
`;

const Image = styled.img`
  transition: 0.4s ease;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const Skeleton = styled.div``;

export default ScrollingImage;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';

const QR = (props) => {
  const { active, handleClose } = props;
  return (
    <Root
      style={{
        opacity: active ? 1 : 0,
        pointerEvents: active ? 'all' : 'none',
      }}
    >
      <Wrapper>
        <Code
          src={require('src/assets/images/icons/wechat_QR.png')}
          alt="WeChat QR Code"
        />
        <Close
          src={require('src/assets/images/icons/close_icon.svg')}
          alt="Close"
          onClick={handleClose}
        />
      </Wrapper>
    </Root>
  );
};

QR.propTypes = {
  active: PropTypes.bool,
  handleClose: PropTypes.func,
};

const Root = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: 0.4s ease;
`;

const Wrapper = styled.div`
  box-shadow: 0 15px 36px 0 rgba(0, 0, 0, 0.15);
  background-color: ${({ theme }) => theme.color.champagne};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('width', 260, 420)}
  ${vw('padding', 15, 38)}
  img {
    display: block;
  }
`;

const Code = styled.img`
  width: 100%;
  height: auto;
  ${vw('margin-bottom', 20, 42)}
`;

const Close = styled.img`
  height: auto;
  cursor: pointer;
  ${vw('width', 45, 83)}
`;

export default QR;

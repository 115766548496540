import React, { useState } from 'react';
import styled from 'styled-components';

import QR from './elements/QR';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import social from 'src/data/social';

const Footer = () => {
  const [isQRActive, setIsQRActive] = useState(false);

  const handleOpenQR = () => {
    setIsQRActive(true);
  };

  const handleCloseQR = () => {
    setIsQRActive(false);
  };

  return (
    <Root>
      {/* Will have to change the following Background component for full website, its adding the pink background to the bottom of the page for the home page. Had issues with overflow cutting off the home background. */}
      <Background />
      <Title>
        <Logo
          src={require('src/assets/images/logos/matchpoint_logo_solid.svg')}
          alt="Matchpoint"
        />
        <p className="strapline white">THE ART OF COMMUNITY</p>
        <Social>
          {social.map((item, index) => (
            <Link
              href={item.to}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
              as={item.to ? 'a' : 'button'}
              onClick={item.to ? (f) => f : () => handleOpenQR()}
            >
              <Icon
                width="35px"
                height="35px"
                viewBox="0 0 35 35"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="_assets"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  {item.outline}
                </g>
              </Icon>
            </Link>
          ))}
        </Social>
      </Title>
      <Contact>
        <h1 className="footer champagne">CONTACT US</h1>
        <p className="footer champagne">
          <span>P: </span>
          <a href="tel:236-868-5051" className="champagne">
            236-868-5051
          </a>
        </p>
        <p className="footer champagne">
          <span>E: </span>
          <a href="mailto:admin@matchpointdev.com" className="champagne">
            admin@matchpointdev.com
          </a>
        </p>{' '}
        <p className="footer champagne">
          <span>A: </span>101- 6386 East Blvd Vancouver <br />
          BC V6M 3V8
        </p>
      </Contact>
      <Disclaimer>
        <p className="footer copyright white">© 2021 MATCHPOINT DEVELOPMENT&nbsp;&nbsp;|&nbsp;&nbsp;SITE BY <a href="https://bamdigital.com" target="_blank">BAM DIGITAL</a></p>
        {/* <p className="footer copyright white">Terms</p>
        <p className="footer copyright white">Privacy</p> */}
      </Disclaimer>
      <QR active={isQRActive} handleClose={handleCloseQR} />
    </Root>
  );
};

const Root = styled.footer`
  background-color: ${({ theme }) => theme.color.brownPrimary};
  padding: ${vwMobile(60)} ${vwMobile(24)} ${vwMobile(20)};
  position: relative;
  @media ${media.tablet} {
    padding: ${vwTablet(60)} ${vwTablet(248)} ${vwTablet(20)};
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(30)} ${vwDesktop(48)};
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    a , a:visited{
      transition: 0.4s ease;
      color:#FFF;
      &:hover {
        color: ${({ theme }) => theme.color.orangePrimary};
      }
    }
  }
`;

const Background = styled.div`
  background-color: ${({ theme }) => theme.color.champagne};
  display: none;
  @media ${media.desktop} {
    display: block;
    width: 100%;
    height: 33.33vh;
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: -1;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    text-transform: uppercase;
  }
  @media ${media.desktop} {
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Contact = styled.div`
  text-align: center;

  h1 {
    text-transform: uppercase;
    margin-bottom: ${vwMobile(20)};
  }
  p {
    span {
      display: none;
    }
  }
  @media ${media.tablet} {
    h1 {
      margin-bottom: ${vwTablet(20)};
    }
  }
  @media ${media.desktop} {
    order: 1;
    text-align: left;
    h1 {
      margin-bottom: ${vwDesktop(28)};
    }
    p {
      span {
        display: inline;
        font-weight: 500;
      }
      br {
        display: none;
      }
    }
  }
`;

const Link = styled.a`
  cursor: pointer;
`;

const Disclaimer = styled.div`
  display: flex;
  /* Revert justify-content when terms&privacy come in */
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  margin-top: ${vwMobile(40)};
  @media ${media.tablet} {
    margin-top: ${vwTablet(40)};
  }
  @media ${media.desktop} {
    margin-top: 0;
    order: 3;
    p:not(:first-of-type) {
      margin-left: ${vwDesktop(30)};
    }
  }
`;

const Social = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 ${vwMobile(31)};
  margin: ${vwMobile(20)} 0 ${vwMobile(40)};
  @media ${media.tablet} {
    padding: 0 ${vwTablet(31)};
    margin: ${vwTablet(20)} 0 ${vwTablet(40)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(243)};
    padding: 0;
    margin: ${vwDesktop(20)} 0 0;
  }
`;

const Logo = styled.img`
  display: block;
  width: 100%;
  margin-bottom: ${vwMobile(10)};
  @media ${media.tablet} {
    margin-bottom: ${vwTablet(10)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(337)};
    margin-bottom: ${vwDesktop(12)};
  }
`;

const Icon = styled.svg`
  display: block;
  width: ${vwMobile(30)};
  @media ${media.tablet} {
    width: ${vwTablet(30)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(35)};
    g {
      transition: 0.4s ease;
    }
    &:hover g {
      fill: ${({ theme }) => theme.color.orangePrimary};
    }
  }
`;

export default Footer;

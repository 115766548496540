import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ArrowButton = (props) => {
  const { to, href, text, disabled, white } = props;

  const handleType = () => {
    if (disabled) {
      return 'button';
    } else if (href) {
      return 'a';
    } else {
      return Link;
    }
  };

  return (
    <Root
      {...props}
      as={handleType()}
      to={to}
      href={href}
      target={href && '_blank'}
      rel="noopener noreferrer"
      disabled={disabled}
      white={white}
    >
      <p className={`nav text-link ${white ? 'white' : 'copper'}`}>{text}</p>
      <svg
        width="46px"
        height="9px"
        viewBox="0 0 46 9"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Arrow</title>
        <g
          id="_assets"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Assets"
            transform="translate(-31.000000, -330.000000)"
            fill={white ? '#fdfbf9' : '#C25822'}
            fillRule="nonzero"
          >
            <path
              id="link_arrow"
              d="M68,330 L77,334.5 L68,339 L68,335 L31,335 L31,334 L68,334 L68,330 Z"
            ></path>
          </g>
        </g>
      </svg>
    </Root>
  );
};

ArrowButton.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
};

const hover = css`
  p {
    color: ${({ theme }) => theme.color.brownDark};
  }
  svg g {
    fill: ${({ theme }) => theme.color.brownDark};
  }
`;

const whiteHover = css`
  p {
    color: ${({ theme }) => theme.color.brownPrimary};
  }
  svg g {
    fill: ${({ theme }) => theme.color.brownPrimary};
  }
`;

const Root = styled(Link)`
  display: flex;
  align-items: center;
  p {
    margin-right: ${vwMobile(15)};
    text-transform: uppercase;
    transition: 0.4s ease;
  }
  img,
  svg {
    display: block;
    width: ${vwMobile(46)};
    opacity: ${(props) => (props.disabled ? 0 : 1)};
  }
  svg g {
    transition: 0.4s ease;
  }
  @media ${media.tablet} {
    p {
      margin-right: ${vwTablet(20)};
    }
    img,
    svg {
      width: ${vwTablet(46)};
    }
  }
  @media ${media.desktop} {
    cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
    p {
      margin-right: ${vwDesktop(20)};
    }
    img,
    svg {
      width: ${vwDesktop(46)};
    }
    &:hover {
      ${(props) => !props.disabled && (props.white ? whiteHover : hover)}
    }
  }
`;

export default ArrowButton;

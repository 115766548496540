import appConfig from 'src/config/app.conf';
const { stage } = appConfig;

export default (property, wMobile, wTablet, wDesktop) => {
  if (typeof wTablet === 'undefined') {
    wTablet = wMobile;
  }

  if (typeof wDesktop === 'undefined') {
    wDesktop = wTablet;
  }

  if (
    property === 'font-size' ||
    property === 'line-height' ||
    property === 'letter-spacing'
  ) {
    return `
      ${property}: ${vwMobile(wMobile)};
      @media (min-width: ${appConfig.mediaQuery.tablet}px) {
        ${property}: ${vwTablet(wTablet)};
      }
      @media (min-width: ${appConfig.mediaQuery.desktop}px) {
        ${property}: ${vwDesktop(wDesktop)};
      }
      @media (min-width: ${appConfig.mediaQuery.xl}px) {
        ${property}: min(${wDesktop * 1.3}px, ${vwDesktop(wDesktop)});
      }
    `;
  } else {
    return `
      ${property}: ${vwMobile(wMobile)};
      @media (min-width: ${appConfig.mediaQuery.tablet}px) {
        ${property}: ${vwTablet(wTablet)};
      }
      @media (min-width: ${appConfig.mediaQuery.desktop}px) {
        ${property}: ${vwDesktop(wDesktop)};
      }
    `;
  }
};

export const vwMobile = (width) => {
  return `${(width / stage.mobile) * 100}vw`;
};

export const vwTablet = (width) => {
  return `${(width / stage.tablet) * 100}vw`;
};

export const vwDesktop = (width) => {
  return `${(width / stage.desktop) * 100}vw`;
};
